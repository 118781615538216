<template>
  <div
    :class="$style['main-bg']"
    class="d-flex justify-content-center">
    <div :class="$style['gif-globe']">
      <img
        src="@/assets/images/icons/giphy2.gif"
        alt="no background"
        :class="$style['gif-style']">
    </div>
    <div style="position: absolute; top: 64%">
      <img
        src="@/assets/images/background/login.png"
        alt="no background"
        :class="$style['image-style']">
    </div>
  </div>
</template>

<style module lang="scss">
@import "@/scss/general/background-image.scss";
</style>
