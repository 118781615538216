<template>
  <div :class="$style['main-div']">
    <div :class="$style['sub-main-div']">
      <div class="d-flex flex-row">
        <!-- <Demo class="col-5 d-flex justify-content-center align-items-center" /> -->
        <LoginForm class="col-12 p-0" />
      </div>
    </div>
    <div :class="$style['contact-holder']">
      <button type="button" class="btn" :class="$style['contact-btn-wrapper']"
        @click="isContactPanelOpen = !isContactPanelOpen">
        <img src="@/assets/images/help-circle.png" alt="help image" :class="$style['help-icon']" />
        <span :class="$style['help-text']">Contact us</span>
      </button>
    </div>
    <background-image />
    <ContactUs @closeContactUsPanel="onCloseContactUsPanel($event)" :isPanelOpen="isContactPanelOpen"/>
  </div>
</template>
<script>
import LoginForm from "@/views/login/login-form.vue"
import Demo from "@/views/demo.vue"
import BackgroundImage from "@/views/background-image.vue"
import ContactUs from "../contact-us.vue";
// import axios from 'axios'

export default {
  name: "Test",
  components: {
    LoginForm,
    Demo,
    BackgroundImage,
    ContactUs
  },
  data() {
    return {
      isContactPanelOpen: false
    }
  },
  methods:{
    onCloseContactUsPanel(value){
      this.isContactPanelOpen = value;
    },
}
}
</script>
<style module lang="scss">@import "@/scss/general/main.scss";</style>
