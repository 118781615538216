// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".login_main-div_GUcpv{position:relative;background-color:#000}.login_sub-main-div_2SrAu{background-color:rgba(5,5,5,.75);position:absolute;z-index:1;width:100vw;height:100vh}.login_sub-main-div_2SrAu>.login_sub_SyrVV{height:100vh}.login_input-name-width_x9kgD{width:48%}.login_contact-holder_6fqPQ{background-color:#38414c;max-width:122px;width:100%;border-radius:22px;height:40px;color:#fff;position:fixed;z-index:1;top:92%;right:40px}.login_contact-holder_6fqPQ .login_contact-btn-wrapper_2gSY-{display:flex;align-items:center;padding:3px 4px 4px 4px}.login_contact-holder_6fqPQ .login_contact-btn-wrapper_2gSY- .login_help-icon_i-Iqf{margin-right:6px}.login_contact-holder_6fqPQ .login_contact-btn-wrapper_2gSY- .login_help-text_2Q0L_{font-size:14px;color:#fff;line-height:1.2}", ""]);
// Exports
exports.locals = {
	"main-div": "login_main-div_GUcpv",
	"sub-main-div": "login_sub-main-div_2SrAu",
	"sub": "login_sub_SyrVV",
	"input-name-width": "login_input-name-width_x9kgD",
	"contact-holder": "login_contact-holder_6fqPQ",
	"contact-btn-wrapper": "login_contact-btn-wrapper_2gSY-",
	"help-icon": "login_help-icon_i-Iqf",
	"help-text": "login_help-text_2Q0L_"
};
module.exports = exports;
