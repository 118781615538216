// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".verify-password-component_label-style_bmIyu[data-v-4f54666c]{margin:10px 0;width:139px;height:24px;font-family:Barlow;font-style:normal;font-weight:500;font-size:20px;line-height:24px;text-align:center;color:#fff;flex:none;order:1;flex-grow:0}.verify-password-component_main-div_3y8Id[data-v-4f54666c]{height:100vh;width:100%;background-color:#000;background-position:50%;background-repeat:no-repeat;background-size:cover;align-content:flex-start;flex-wrap:wrap}.verify-password-component_logo-div_3YiEg[data-v-4f54666c],.verify-password-component_main-div_3y8Id[data-v-4f54666c]{display:flex;justify-content:center}.verify-password-component_logo-div_3YiEg[data-v-4f54666c]{margin-top:74px;width:404px;height:337px;background:rgba(34,38,49,.6);border:.2px solid #879aa6;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);border-radius:10px;flex-direction:column;align-items:center;border:none}.verify-password-component_content_2QICL[data-v-4f54666c]{width:340px;height:51px;font-family:Barlow;font-style:normal;font-weight:400;font-size:14px;line-height:17px;letter-spacing:.4px;flex:none;order:0;align-self:stretch;flex-grow:0}.verify-password-component_content_2QICL[data-v-4f54666c],button[data-v-4f54666c]{display:flex;align-items:center;color:#eef4f7}button[data-v-4f54666c]{background:transparent;border:none;justify-content:center}.verify-password-component_days_25upe[data-v-4f54666c]{color:#00f0ff;padding-left:4px}.verify-password-component_icon-login_2sQYw[data-v-4f54666c]{color:#fff}", ""]);
// Exports
exports.locals = {
	"label-style": "verify-password-component_label-style_bmIyu",
	"main-div": "verify-password-component_main-div_3y8Id",
	"logo-div": "verify-password-component_logo-div_3YiEg",
	"content": "verify-password-component_content_2QICL",
	"days": "verify-password-component_days_25upe",
	"icon-login": "verify-password-component_icon-login_2sQYw"
};
module.exports = exports;
