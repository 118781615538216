import { axiosIns } from "@app/utility";
export async function sentOTP(otpCodeData) {
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: "/account/auth/verify-otp",
    data: otpCodeData,
  };
  return axiosIns(config);
}
export async function resendOTP(otpEmail) {
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: "/account/auth/resend-otp",
    data: otpEmail,
  };
  return axiosIns(config);
}

export async function sendLoginOTP (otpCodeData) {
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: "/account/auth/verify-auth-otp",
    data: otpCodeData,
  };
  return axiosIns(config);
}