<template>
  <div>
    <div class="position-relative">
      <div v-if="showGuidance" :class="$style['password-guidance-container']" ref="passwordGuidanceContainer">
        <div :class="$style['status']">
          {{ guidanceData.status }}
        </div>
        <div :class="$style['bar']" class="progress">
          <div class="progress-bar" role="progressbar" :class="guidanceData.percentage <= 75 ? $style[`orange-bg`] : $style['green-bg']" :style="`width: ${guidanceData.percentage}%`" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div :class="$style['title']">
          Password requirements:
        </div>
        <div v-for="item in guidanceData.items" :key="item">
          <div :class="[$style['item'], item.conditionMet ? $style['condition-met'] : '']">
            <img src="@/assets/images/icons/check-circle-outline.svg" alt="icon">
            {{ item.text }}
          </div>
        </div>
      </div>
      <span :class="$style['label-style']">{{ label }}</span>
      <div :class="$style['icon-parent-position']">
        <input
          :id="idPassword"
          :input="modelValue"
          :value="modelValue"
          :type="showPassword ? 'text' : 'password'"
          class="mt-2"
          :class="$style['dronos-formcontrol']"
          :no-label="true"
          :placeholder="placeholder"
          :name="name"
          required
          @input="handleInput"
          @keyup="handleKeyup"
        >
        <div
          v-if="isIcon"
          :class="$style['icon-position']"
          @click="showPassword = !showPassword"
        >
          <feather-icon
            v-if="!showPassword"
            type="eye"
            size="20"
            :class="$style['icon-color']"
          />
          <feather-icon
            v-else
            type="eye-off"
            size="20"
            :class="$style['icon-color']"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped module lang="scss">
@import '@/components/scss/asset.scss';
@import '@/components/other/dronos-input/dronos-input.scss';
</style>

<script>
export default {
  components:
        {
        },
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    placeholder: {
      type: String,
      default: 'Enter password',
    },
    name: {
      type: String,
      default: 'Password',
    },
    label: {
      type: String,
      default: 'Label Password',
    },
    idPassword: {
      type: String,
      default: 'password',
    },
    modelValue: {
      type: String,
      default: "",
    },
    guidance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
      isIcon: false,
      showGuidance: false,
      guidanceData: {
        percentage: 0,
        status: 'Weak Password',
        items: [
          {
            text: 'At least 12 characters',
            regex: /.{12,}/,
            conditionMet: false,
          },
          {
            text: 'At least 1 uppercase letter (A-Z)',
            regex: /[A-Z]/,
            conditionMet: false,
          },
          {
            text: 'At least 1 lowercase letter (a-z)',
            regex: /[a-z]/,
            conditionMet: false,
          },
          {
            text: 'At least 1 number (0-9)',
            regex: /[0-9]/,
            conditionMet: false,
          },
          {
            text: 'At least 1 special character',
            regex: /[^A-Za-z0-9]/,
            conditionMet: false,
          },
          {
            text: 'No space allowed',
            regex: /^\S*$/,
            conditionMet: false,
          },
          {
            text: 'No repetitive character',
            regex: /^(?!.*(.)\1).*$/,
            conditionMet: false,
          },
        ],
      },
    }
  },
  watch: {
    modelValue: {
      handler(val){
        if (this.guidance) {
          this.guidanceValidation(val)
        }
        if (val.length > 0){
          this.isIcon = true
        } else {
          this.isIcon = false
        }
      },
    },
  },
  mounted() {
    this.showPassword = false
    this.DPasswordEnter()
    document.addEventListener("click", this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      if (this.showGuidance && !this.$refs.passwordGuidanceContainer.contains(event.target)) {
        this.showGuidance = false
      }
    },
    guidanceValidation(val) {
      this.showGuidance = true
      this.guidanceData.items = this.guidanceData.items.map(data => ({
        ...data,
        conditionMet: data.regex.test(val),
      }))
      const resolved = this.guidanceData.items.filter(data => data.conditionMet).length
      this.guidanceData.status = 'Weak Password'
      if (resolved > 3) {
        this.guidanceData.status = 'Fair Password'
      }
      if (resolved > 4) {
        this.guidanceData.status = 'Strong Password'
      }
      this.guidanceData.percentage = resolved / this.guidanceData.items.length * 100
    },
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
    handleKeyup(event){
      this.$emit('keyup', event.target.value)
    },
    DPasswordEnter() {
      const input = document.getElementById(this.idPassword)
      input.addEventListener("keypress", event => {
        if (event.key === "Enter") {
          this.$emit('enter', event.target.value)
        }
      })
    },
  },
}

</script>
