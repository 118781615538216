<template>
  <div>
    <div :class="[$style.demoCardAll]">
      <div :class="[$style.demoCard]">
        <div :class="$style['demo-images']">
          <img alt="No Image" src="@/assets/images/demo1.png">
          <img alt="No Image" src="@/assets/images/demo2.png">
          <img alt="No Image" src="@/assets/images/demo3.png">
          <img alt="No Image" src="@/assets/images/demo4.png">
        </div>
        <div :class="[$style.showcaseDemo]">
          <div :class="[$style.showcaseDemoDetail]">
            Showcase Demos
          </div>
          <div :class="$style.demoDesc">Try all the included demo industry and play around
            with all features and functionality</div>
          <div style="margin-top: 49px;">
            <button :class="[$style.onHover, $style.demoBtn]" class="btn" @click="goToDemo()">
              <span :class="$style.demoText">FREE DEMOS</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import '@/scss/demo/demo.scss';
</style>

<script>

export default {
  methods: {
    goToDemo() {
      window.location.href = '/'
    },
  },
}
</script>
