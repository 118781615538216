<template>
  <div :class="$style['main-div']">
    <div :class="$style['sub-main-div']">
      <div
        class="d-flex justify-content-center">
        <div :class="$style['forget-div']">
          <div
            class="d-flex align-items-center"
            :class="$style['back-button-div']">
            <router-link :to="{name: 'login'}">
              <button>
                <img
                  class="mr-4"
                  src="@/assets/images/arrow_back.png"
                  alt="arrow-back">
                <span>BACK</span>
              </button>
            </router-link>
          </div>
          <div>
            <img
              width="105"
              src="@/assets/images/logo.svg"
              alt="aerodyne-logo">
          </div>
          <div>
            <label
              for=""
              :class="$style['forget-label']">Forgot Password?</label>
          </div>
          <div>
            <p :class="$style.content">
              Please give the email address associated with your account so we can reset your password. Thank
              you!
            </p>
          </div>
          <div>
            <input
              v-model="username"
              type="email"
              class="mt-1"
              :no-label="true"
              placeholder="Enter email address"
              name="Email"
              :class="[$style['dronos-formcontrol'],{[$style['is-invalid']] : !isEmailValid }]"
              @keyup.enter="sendResetPass()">
            <div
              v-if="!isEmailValid"
              class="mb-4"
              :class="$style.error">
              Please enter a valid email
            </div>
            <div>
              <Btn
                widithbtn="340"
                heightbtn="0"
                title="SUBMIT"
                @click="sendResetPass()" />
            </div>
            <!-- </form> -->
            <div :class="$style['account-text']">
              <span :class="$style['very-light-grey']">Don’t have account? </span>
              <router-link :to="{name: 'register'}">
                <button :class="$style.signupbtn">
                  Sign up
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <background-image />
  </div>
</template>
<script>
import button from "@/components/other/dronos-button.component.vue"
import BackgroundImage from '@/views/background-image.vue'
import { authService } from '@/api/auth'
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"
import { useToast } from "vue-toastification"
import { ERROR_MSG } from '@core/utils/constants'

export default {
  components:
    {
      Btn: button,
      BackgroundImage,
    },
  data() {
    const TOAST = useToast()
    return {
      // eslint-disable-next-line new-cap
      authService: new authService(),
      username: null,
      isEmailValid: true,
      TOAST,
    }
  },
  methods: {
    sendResetPass() {
      this.isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.username)

      if (this.isEmailValid) {
        const data = {
          email: this.username,
        }
        try {
          this.authService.forgotPassword(data).then(response => {
            this.TOAST.clear()
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: response.data.message,
                  img: require("@/assets/toastification/user.svg"),
                  iconBg: "#06f7a1",
                  type: "dronos-success-toast",
                },
              },
              {
                toastClassName: "dronos-success-toast",
              },
            )
            setTimeout(() => {
              this.$router.push({ name: 'verify-email-otp', params: { email: this.username, type: 'forgot-password' } })
            }, 500)
          }).catch(error => {
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: error.response.data.message,
                  // eslint-disable-next-line global-require
                  img: require("@/assets/toastification/x.svg"),
                  iconBg: "#ff4646",
                },
              },
              {
                toastClassName: "dronos-error-toast",
              },
            )
          })
        } catch {
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: ERROR_MSG.GENERAL,
                // eslint-disable-next-line global-require
                img: require("@/assets/toastification/x.svg"),
                iconBg: "#ff4646",
              },
            },
            {
              toastClassName: "dronos-error-toast",
            },
          )
        }
      }
    },
  },
}

</script>

<style module lang="scss">
  @import '@/scss/general/forgot-password.scss';
  @import '@/scss/validation/valid-main.scss';
  @import '@/scss/general/main.scss';
  @import '@/scss/theme/theme.scss';
  @import '@/components/other/dronos-input/dronos-input.scss';
</style>
<style scoped>
  button {
        display: flex;
        background: transparent;
        border: none;
        color: #EEF4F7;
        align-items: center;
        justify-content: center;
    }
</style>
