import { createRouter, createWebHistory } from "vue-router";
import Login from '../views/login/login.vue'
import Register from '../views/register.vue'
import forgotPassword from '../views/forgot-password.vue'
import noWorkspace from '../views/no-workspace/no-workspace.vue'
import resetPassword from '../views/reset-password.vue'
import verifyOyp from '../components/other/verify-email-otp.vue'
import changePassword from '../views/change-password.vue'
 
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    alias: ["/login"],
    name: "login",
    component: Login,
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/register",
    alias: ["/register"],
    name: "register",
    component: Register,
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/forgot-password",
    alias: ["/forgot-password"],
    name: "forgot-password",
    component: forgotPassword,
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/no-workspace",
    alias: ["/no-workspace"],
    name: "no-workspace",
    component: noWorkspace,
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/reset-password",
    alias: ["/reset-password"],
    name: "reset-password",
    component: resetPassword,
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/verify-otp/:type/:email/:rememberMe?",
    alias: ["/verify-otp"],
    name: "verify-email-otp",
    component: verifyOyp,
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: "/change-password",
    alias: ["/change-password"],
    name: "change-password",
    component: changePassword,
    meta: {
      layout: "full",
      auth: false,
    },
  },
];
 
const authRoutes = [
  "login",
  "register",
  "forgot-password",
  "reset-password",
  "no-workspace",
  "verify-email-otp",
];
const specialPath = [
  "/canvas/amplitel/digital-twin",
  "/canvas/amplitel/from-above",
  "/canvas/amplitel/los",
  "/canvas/amplitel/side-view",
  "/canvas/amplitel/tower-view",
];
 
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});
 
const userLoggedIn = localStorage.getItem('dronos-token')
 
router.afterEach((to) => {
  if (!authRoutes.includes(to.name) && !specialPath.includes(to.path) && !userLoggedIn) {
    window.history.replaceState({}, "", "/");
  }
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
 
export default router;