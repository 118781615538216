// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".verify-signup-component_label-style_7UnKn[data-v-a6eddf44]{margin:10px 0;width:139px;height:24px;font-family:Barlow;font-style:normal;font-weight:500;font-size:20px;line-height:24px;text-align:center;color:#fff;flex:none;order:1;flex-grow:0}.verify-signup-component_main-div_3DljX[data-v-a6eddf44]{height:100vh;width:100%;background-color:#000;background-position:50%;background-repeat:no-repeat;background-size:cover;align-content:flex-start;flex-wrap:wrap}.verify-signup-component_logo-div_YSgzt[data-v-a6eddf44],.verify-signup-component_main-div_3DljX[data-v-a6eddf44]{display:flex;justify-content:center}.verify-signup-component_logo-div_YSgzt[data-v-a6eddf44]{margin-top:74px;width:404px;height:337px;background:rgba(34,38,49,.6);border:.2px solid #879aa6;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);border-radius:10px;flex-direction:column;align-items:center;border:none}.verify-signup-component_content_1cnD2[data-v-a6eddf44]{width:340px;height:51px;font-family:Barlow;font-style:normal;font-weight:400;font-size:14px;line-height:17px;letter-spacing:.4px;flex:none;order:0;align-self:stretch;flex-grow:0}.verify-signup-component_content_1cnD2[data-v-a6eddf44],button[data-v-a6eddf44]{display:flex;align-items:center;color:#eef4f7}button[data-v-a6eddf44]{background:transparent;border:none;justify-content:center}.verify-signup-component_days_2jRH2[data-v-a6eddf44]{color:#00f0ff;padding-left:4px}.verify-signup-component_icon-login_2IsWT[data-v-a6eddf44]{color:#fff}", ""]);
// Exports
exports.locals = {
	"label-style": "verify-signup-component_label-style_7UnKn",
	"main-div": "verify-signup-component_main-div_3DljX",
	"logo-div": "verify-signup-component_logo-div_YSgzt",
	"content": "verify-signup-component_content_1cnD2",
	"days": "verify-signup-component_days_2jRH2",
	"icon-login": "verify-signup-component_icon-login_2IsWT"
};
module.exports = exports;
