// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".demo_showcaseDemoDetail_54UD1{font-weight:400;color:#fff;font-size:20px}.demo_showcaseDemo_9Wd0x{margin-top:32px}.demo_demoCardAll_1U43s{position:relative}.demo_demoText_2L4__{font-weight:500;font-size:15px;line-height:29px;letter-spacing:.08em;text-transform:uppercase;color:#c8c8c8}.demo_demoBtn_2UNYk{background:rgba(0,240,255,.2);border:1px solid #8ef9f9;box-shadow:0 0 15px rgba(0,240,255,.3);border-radius:5px;width:100%}.demo_demoCard_1sMXk{background:rgba(34,38,49,.6);border:.2px solid #879aa6;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);border-radius:10px;width:413px;padding:32px}.demo_demoDesc_3gtai{font-weight:300;color:#fff;font-size:14px;width:304px;line-height:16.8px;width:100%;margin-top:8px}.demo_demo-images_3kiu4{width:inherit}.demo_demo-images_3kiu4 img{width:87px;height:208px}", ""]);
// Exports
exports.locals = {
	"showcaseDemoDetail": "demo_showcaseDemoDetail_54UD1",
	"showcaseDemo": "demo_showcaseDemo_9Wd0x",
	"demoCardAll": "demo_demoCardAll_1U43s",
	"demoText": "demo_demoText_2L4__",
	"demoBtn": "demo_demoBtn_2UNYk",
	"demoCard": "demo_demoCard_1sMXk",
	"demoDesc": "demo_demoDesc_3gtai",
	"demo-images": "demo_demo-images_3kiu4"
};
module.exports = exports;
