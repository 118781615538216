<template>
  <div :class="[$style['ticket-form-taost-wrapper'], isTicketToastOpen ? $style['active'] : $style['inactive']]">
    <div :class="$style['toast-holder']">
      <div :class="$style['img-wrapper']">
        <img :src="imageConstants.tickIcon" alt="tick-icon" />
      </div>
      <h2>Query Submitted</h2>
      <p :class="$style['content']">Your query has been submitted to our Support Team.
        We'll be reaching out shortly. Thank you for your feedback.</p>
    </div>
  </div>
</template>
<script>
export default ({
  props: {
    isTicketToastOpen: Boolean,
    imageConstants: Object
  },

  data(){
    console.log("ICONS", this.imageConstants)
    
  }
})
</script>
<style module lang="scss">
@import "@/scss/general/contact-us.scss";
</style>
