// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".background-image_main-bg_3SjbY{background-color:#000;position:relative;width:100vw;height:100vh;overflow:hidden}.background-image_gif-globe_hPsxN{animation:background-image_bottomUp_3nTuC;animation-duration:10s;position:absolute;top:54%;width:80%}.background-image_gif-style_3eeCZ{opacity:.9}.background-image_gif-style_3eeCZ,.background-image_image-style_32094{width:100%;-o-object-fit:contain;object-fit:contain}.background-image_image-style_32094{opacity:1}@keyframes background-image_bottomUp_3nTuC{0%{top:100%}to{top:54%}}", ""]);
// Exports
exports.locals = {
	"main-bg": "background-image_main-bg_3SjbY",
	"gif-globe": "background-image_gif-globe_hPsxN",
	"bottomUp": "background-image_bottomUp_3nTuC",
	"gif-style": "background-image_gif-style_3eeCZ",
	"image-style": "background-image_image-style_32094"
};
module.exports = exports;
