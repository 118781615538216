<template>
  <div :class="$style['main-div']">
    <div :class="$style['logo-div']">
      <div>
        <img
          width="105"
          src="@/assets/images/logo.svg"
          alt="aerodyne-logo"
        >
      </div>
      <div>
        <label
          for=""
          :class="$style['label-style']"
        >Verification link sent</label>
      </div>
      <div class="mt-3">
        <p :class="$style.content">
          Great! We've just sent a verification link to your inbox. Please click on the link to confirm your email address and complete your account setup.
        </p>
        <p :class="$style.content">
          If you don't receive the email within a few minutes, please check your spam folder.
        </p>
      </div>
      <button @click="$emit('back-to-login')">
        Back to Login
        <feather-icon
          type="chevron-right"
          size="16"
          :class="$style['icon-login']"
        />
      </button>
    </div>
  </div>
</template>

<style scoped module lang="scss">
  @import '@/components/other/verify-otp/verify-otp.scss';
</style>
