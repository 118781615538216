// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[data-v-8dd9d442]:root{--widthBtn:\"340\";--heightBtn:\"0\";--marginDiv:\"15\"}button[data-v-8dd9d442]{padding-top:21px;padding-bottom:21px;background-color:rgba(0,240,255,.1);border-color:#00f0ff;font-size:10px;align-items:center;display:flex;justify-content:center;font-size:14px;font-family:Barlow;border-radius:4px;letter-spacing:1.25px;width:100%}button[data-v-8dd9d442],button[data-v-8dd9d442]:hover{color:#fff}.dronos-button-component_button-custom_1HVv_[data-v-8dd9d442]{width:var(--widthBtn);height:var(--heightBtn)}.dronos-button-component_margin-div_4Zhkp[data-v-8dd9d442]{margin-top:var(--marginDiv)}", ""]);
// Exports
exports.locals = {
	"button-custom": "dronos-button-component_button-custom_1HVv_",
	"margin-div": "dronos-button-component_margin-div_4Zhkp"
};
module.exports = exports;
