// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".no-workspace-main_showcaseDemoDetail_Q0BAr{font-weight:400;color:#fff;font-size:20px}.no-workspace-main_showcaseDemo_2Oy_9{margin-top:32px}.no-workspace-main_demoCardAll_1_Eeo{position:relative}.no-workspace-main_demoText_ZVPV_{font-weight:500;font-size:15px;line-height:29px;letter-spacing:.08em;text-transform:uppercase;color:#c8c8c8}.no-workspace-main_demoBtn_2vqOb{background:rgba(0,240,255,.2);border:1px solid #8ef9f9;box-shadow:0 0 15px rgba(0,240,255,.3);border-radius:5px;width:100%}.no-workspace-main_demoCard_3-FjD{background:rgba(34,38,49,.6);border:.2px solid #879aa6;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);border-radius:10px;width:413px;padding:32px}.no-workspace-main_demoDesc_2Gymq{font-weight:300;color:#fff;font-size:14px;width:304px;line-height:16.8px;width:100%;margin-top:8px}.no-workspace-main_demo-images_EkP5l{width:inherit}.no-workspace-main_demo-images_EkP5l img{width:87px;height:208px}", ""]);
// Exports
exports.locals = {
	"showcaseDemoDetail": "no-workspace-main_showcaseDemoDetail_Q0BAr",
	"showcaseDemo": "no-workspace-main_showcaseDemo_2Oy_9",
	"demoCardAll": "no-workspace-main_demoCardAll_1_Eeo",
	"demoText": "no-workspace-main_demoText_ZVPV_",
	"demoBtn": "no-workspace-main_demoBtn_2vqOb",
	"demoCard": "no-workspace-main_demoCard_3-FjD",
	"demoDesc": "no-workspace-main_demoDesc_2Gymq",
	"demo-images": "no-workspace-main_demo-images_EkP5l"
};
module.exports = exports;
