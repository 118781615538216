<template>
  <div :class="$style['main-div']">
    <div :class="$style['logo-div']">
      <div>
        <img
          width="105"
          src="@/assets/images/logo.svg"
          alt="aerodyne-logo"
        >
      </div>
      <div>
        <label
          for=""
          :class="$style['label-style']"
        >Link sent</label>
      </div>
      <div>
        <p :class="$style.content">The link to reset your password has been sent to your email. Be sure to check your
          spam folder if the
          email isn’t in your inbox. </p>
        <p :class="$style.content">
          The link only valid for <span :class="$style.days"> 15 days.</span>
        </p>
      </div>
      <button @click="$emit('back-to-login')">
        Back to Login
        <feather-icon
          type="chevron-right"
          size="16"
          :class="$style['icon-login']"
        />
      </button>
    </div>
  </div>
</template>
<style scoped module lang="scss">
@import '@/components/other/verify-otp/verify-otp.scss';
</style>
