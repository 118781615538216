import { getUserInfo } from '@/api/user';
import { state } from '@app/utility';
import { useToast } from "vue-toastification"
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"

export async function continueToLogin ({isUserVerified, message, token, email, router}) {
  const TOAST = useToast();
  if (isUserVerified === false) {
    router.push({ name: 'verify-email-otp', params: { email: email, type: 'email-verification-otp' } })
  } else {
    const dronosToken = token.substring(7);
    const currentTime = new Date().getTime();
    const item = {
      value: dronosToken,
      expiry: currentTime,
    };
    const { data: userData } = await getUserInfo(dronosToken);
    if (userData?.member?.length > 0) {
      localStorage.setItem('user-data', JSON.stringify(userData));
      state.setDronosToken(item);
      localStorage.setItem('email', email);
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      setTimeout(() => {
        router.push({ name: 'no-workspace' });
      }, 500);
    }
  }
}