<template>
  <div :class="$style['main-div']">
    <div class="position-absolute">
      <background-image />
    </div>
    <div class="d-flex justify-content-center">
      <div :class="$style['component-div']">
        <img width="105" src="@/assets/images/logo.svg" alt="No image">
        <span :class="$style['component-label']">
          Check your email
        </span>
        <div class="mt-3">
          <p :class="$style.content">
            We've just sent a one-time password (OTP) to your inbox.
          </p>
        </div>
        <div class="mt-1">
          <input-otp :set-otp-value="setOtp" />
        </div>
        <div class="py-4">
          <button :disabled="otp.length < 6" type="button" :class="[$style['on-hover'], $style['verify-btn']]" class="btn" @click="verifyOtp">
            <span :class="$style['login-text']">{{ submitTitle }}</span>
          </button>
        </div>
        <div :class="$style['resend']">
          <span>Didn’t receive the email?
            <button :disabled="!resendEmail" :class="$style['btn-resend']" @click="(resendEmail = !resendEmail), resendOTP()">
              Click to resend
            </button>
          </span>
        </div>
        <div v-if="(counter && counter !== 0)" :class="$style['resend']" class="d-flex justify-content-center">
          <span>
            Please use "Click to resend" button if you do not receive OTP in {{ counter }} seconds.
          </span>
        </div>
        <div :class="$style['back-login']" @click="backLogin()">
          <span>Back to Login > </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authService } from '@/api/auth'
import backgroundImage from '@/views/background-image'
import { sentOTP, resendOTP, sendLoginOTP } from '@/api/verify-otp';
import { useToast } from "vue-toastification"
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"
import inputOtp from './otp-input.vue'
import { continueToLogin } from '@core/utils/login/login-util';
import { OTP_EXPIRY_TIME, DEFAULT_OTP_TIMER } from '@/views/login/login-constant'

export default {
  name: 'EmailVerificationOtp',
  components: {
    inputOtp,
    backgroundImage,
  },
  data() {
    const TOAST = useToast()
    return {
      authService: new authService(),
      resendEmail: true,
      intervalId: null,
      counter: DEFAULT_OTP_TIMER,
      minute: 60,
      minuteCycle: 5,
      email: '',
      otp: '',
      prevOTP: '',
      context: 'email-verification',
      submitTitle: 'Verify Email',
      TOAST,
      otpLogin: 'otp-login-',
      emailVerificationOtp: 'email-verification',
      forgotPassword: 'forgot-password',
      rememberMe: false,
      clearToastTimeout: 1800,
      errorToastTimeout: 2150,
    }
  },
  mounted() {
    this.resendEmail = false
    const routeParams = this.$route.params
    this.email = routeParams.email
    this.context = routeParams.type;
    this.getOTPTimer()
    this.setTimer()
    switch (routeParams.type) {
      case this.forgotPassword:
        this.submitTitle = 'Submit';
        break;
      case this.emailVerificationOtp:
        break;
      default: // otpLogin:
        if(routeParams.rememberMe === 'true') {
          this.rememberMe = true
        }
    }
  },
  unmounted() {
    this.stopTimer()
    localStorage.removeItem(OTP_EXPIRY_TIME)
  },
  methods: {
    setOTPTimer(timer) {
      this.counter = timer
      localStorage.setItem(OTP_EXPIRY_TIME, timer)
    },
    getOTPTimer() {
      const otpExpiryTime = localStorage.getItem(OTP_EXPIRY_TIME)
      this.counter = otpExpiryTime ?? DEFAULT_OTP_TIMER
    },
    async verifyOtp() {
      const payLoad = {
        // 'email': window.location.search.split('=')[1],
        email: this.email,
        otp: this.otp,
        context: this.context,
        rememberMe: this.rememberMe,
      }
      try {
        if (this.otp !== this.prevOTP) {
          this.prevOTP = this.otp
          let response;
          if(this.context === this.otpLogin) {
            response = await sendLoginOTP(payLoad);
          } else {
            response = await sentOTP(payLoad);
          }
          const message = response.data.message;
          const verified = response.data.verified;
          const token = response.data.token;
          const changePasswordRequired = response.data.changePasswordRequired
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: message,
                img: require("@/assets/toastification/user.svg"),
                iconBg: "#06f7a1",
                type: "dronos-success-toast",
              },
            },
            {
              toastClassName: "dronos-success-toast",
            },
          )
          this.clearToast()
          if (changePasswordRequired) {
            localStorage.setItem('email-verify', this.email)
            this.$router.push({ name: 'reset-password' })
            return
          }
          switch(this.context) {
            case this.otpLogin:
              await continueToLogin({ message, verified, token, email: this.email });
              break;
            case this.emailVerificationOtp:
              localStorage.setItem('email-verify', this.email);
              this.$router.push({ name: 'login' })
              break;
            case this.forgotPassword:
              localStorage.setItem('email-verify', this.email);
              this.$router.push({ name: 'reset-password' })
              break;
          }
        }
      } catch (error) {
        setTimeout(() => {
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: error.response.data.message,
                // eslint-disable-next-line global-require
                img: require("@/assets/toastification/x.svg"),
                iconBg: "#ff4646",
              },
            },
            {
              toastClassName: "dronos-error-toast",
            },
          )
          this.clearToast()
        }, this.errorToastTimeout)
        if (error.response.status === 406) {
          this.$router.push({ name: 'no-workspace' })
        }
      }
    },
    async resendOTP() {
      const payLoadOTP = {
        email: this.email,
        context: this.context,
      }
      try {
        const { data } = await resendOTP(payLoadOTP)
        const { otpExpiryTimeInSeconds } = data
        let timerToUse = DEFAULT_OTP_TIMER
        if (otpExpiryTimeInSeconds) {
          timerToUse = otpExpiryTimeInSeconds
        }
        this.setOTPTimer(timerToUse)
        this.setTimer()
        this.TOAST(
          {
            component: DToast,
            ToastID: "custom-toast",
            props: {
              title: data.message,
              img: require("@/assets/toastification/user.svg"),
              iconBg: "#06f7a1",
              type: "dronos-success-toast",
            },
          },
          {
            toastClassName: "dronos-success-toast",
          },
        )
        this.clearToast()
      } catch (error) {
        this.counter = this.minute * this.minuteCycle
        this.setTimer()
        setTimeout(() => {
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: error.response.data.message,
                // eslint-disable-next-line global-require
                img: require("@/assets/toastification/x.svg"),
                iconBg: "#ff4646",
              },
            },
            {
              toastClassName: "dronos-error-toast",
            },
          )
        }, this.errorToastTimeout)
        this.clearToast()
      }
    },
    setTimer() {
      this.intervalId = setInterval(() => {
        if (this.counter > 0) {
          this.counter--
          localStorage.setItem(OTP_EXPIRY_TIME, this.counter)
        } else {
          this.stopTimer()
          this.resendEmail = true
        }
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.intervalId)
      this.intervalId = null
    },
    backLogin() {
      this.$router.push({ name: 'login' })
    },
    setOtp(value) {
      this.otp = value.join('')
    },
    clearToast() {
      setTimeout(() => {
        this.TOAST.clear()
      }, this.clearToastTimeout)
    },
  },
}
</script>
<style scoped module lang="scss">
@import '@/scss/component/component-main.scss';
@import "@/scss/general/main.scss";
</style>
