<template>
    <div :class="style['main-div']">
        <div :class="style['sub-main-div']">
            <!-- class="d-flex justify-content-center align-items-center" -->
            <!-- <div style="position: relative;" class="d-flex justify-content-center align-items-center"> -->
            <div class="d-flex flex-row justify-content-end">
                <noworkspacemain class="col-7 p-0" />
            </div>
        </div>
        <background-image />
    </div>
</template>
<script>
import noworkspacemain from "./no-workspace-main.vue"
import Demo from "@/views/demo.vue"
import BackgroundImage from "@/views/background-image.vue"
import style from './no-workspace.module.scss'
// import axios from 'axios'

export default {
    name: "Test",
    components: {
        noworkspacemain,
        Demo,
        BackgroundImage,
    },
    data() {
        return {

            style,
        };
    },
}
</script>
  