<template>
  <div :class="style['login-card']" class="m-0 d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-center">
        <img src="@/assets/images/logo/aerodyne_v2.png" :class="style['hidden-logo']" alt="aerodyne-logo">
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center" :class="style['welcome-text']">
        User is not assigned to any workspace
        <div class="mt-4 col-4">
          <button :class="[$style.onHover, $style.demoBtn]" class="btn" @click="goToLogin()">
            <span :class="$style.demoText">BACK TO LOGIN</span>
          </button>
      </div>
      </div>
    </div>
  </div>
</template>
<style module lang="scss">
@import '@/scss/demo/demo.scss';
</style>
<script>
import style from './no-workspace.module.scss'
export default {
  components: {
  },
  data() {
    return {
      style
    }
  },
  methods: {
    goToLogin() {
      window.location.href = 'login'
    }
  },
}
</script>