<template>
  <div :class="$style['main-div']">
    <div :class="$style['sub-main-div']">
      <div
        v-if="!isSuccess"
        class="d-flex justify-content-center"
      >
        <div :class="$style['forget-div']">
          <div>
            <img
              width="105"
              src="@/assets/images/logo.svg"
              alt="aerodyne-logo">
          </div>
          <div>
            <label
              for=""
              :class="$style['forget-label']"
            >Reset Password</label>
          </div>
          <div>
            <!-- <form action=""> -->
            <div class="mb-3">
              <d-password
                id="login-password"
                v-model="newPassword"
                label="New Password"
                placeholder="Enter New Password"
                :class="{ [$style['is-invalid']]: !isPasswordValid }" :guidance="true" />
              <div
                v-if="!isPasswordValid"
                class="mb-4"
                :class="$style.error">
                Password should not contain less than 8 characters
              </div>
            </div>
            <div>
              <d-password
                id="login-password"
                v-model="retypePassword"
                label="Confirm New Password"
                placeholder="Confirm New Password"
                :class="{ [$style['is-invalid']]: !isPasswordConfirmValid }" />
              <div
                v-if="!isPasswordConfirmValid"
                class="mb-4"
                :class="$style.error">
                {{ errorPasswordConfirm }}
              </div>
            </div>
            <div>
              <Btn
                widithbtn="340"
                heightbtn="0"
                title="RESET PASSWORD"
                @click="validatePassword" />
            </div>
            <!-- <div class="mb-3">
              <span :class="$style['label-style']">New password</span>
              <input
                v-model="newPassword"
                type="password"
                class="mt-2"
                :no-label="true"
                placeholder="Enter password"
                :class="{ [$style['is-invalid']]: !isPasswordValid }"
                name="Password"
              >
              <div
                v-if="!isPasswordValid"
                class="mb-4"
                :class="$style.error"
              >
                Password should not contain less than 8 characters
              </div>
            </div> -->
            <!-- <div>
              <span :class="$style['label-style']">Confirm new password</span>
              <input
                v-model="retypePassword"
                type="password"
                class="mt-2"
                :no-label="true"
                placeholder="Enter password"
                :class="{ [$style['is-invalid']]: !isPasswordConfirmValid }"
                name="retypePassword"
              >
              <div
                v-if="!isPasswordConfirmValid"
                class="mb-4"
                :class="$style.error"
              >
                {{ errorPasswordConfirm }}
              </div>
            </div> -->

            <!-- <div style="margin-top: 16px">
              <Btn
                widithbtn="340"
                heightbtn="0"
                title="RESET PASSWORD"
                @click="validatePassword"
              />
            </div> -->
            <!-- </form> -->
          </div>
        </div>
      </div>
      <template v-else>
        <verify-password @back-to-login="$router.push({ name: 'login' })" />
      </template>
    </div>
    <background-image />
  </div>
</template>
<script>
import VerifyPassword from "@/components/other/verify-password.component.vue"
import button from "@/components/other/dronos-button.component.vue"
import BackgroundImage from "@/views/background-image.vue"
import { authService } from "@/api/auth"
import DPassword from "@/components/other/dronos-input/dronos-input-password.component.vue"
import { useToast } from "vue-toastification"
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"

export default {
  components: {
    Btn: button,
    VerifyPassword,
    BackgroundImage,
    DPassword,
  },
  data() {
    const TOAST = useToast()
    return {
      // eslint-disable-next-line new-cap
      authService: new authService(),
      newPassword: "",
      retypePassword: "",
      isSuccess: false,
      errorPasswordConfirm: "This field is required",
      isPasswordValid: true,
      isPasswordConfirmValid: true,
      TOAST,
    }
  },
  methods: {
    validatePassword() {
      this.isPasswordValid = this.newPassword.length > 7
      this.isPasswordConfirmValid = this.retypePassword === this.newPassword
      if (
        this.retypePassword.length > 0
        && this.retypePassword !== this.newPassword
      ) {
        this.errorPasswordConfirm = "The password does not match"
      }

      if (this.isPasswordValid && this.isPasswordConfirmValid) {
        this.resetPassword()
      }
    },
    resetPassword() {
      // this.isSuccess = true
      const emails = localStorage.getItem("email-verify")
      const data = {
        email: emails,
        password: this.retypePassword,
      }
      try {
        this.authService.resetPassword(data).then(response => {
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: response.data.message,
                img: require("@/assets/toastification/user.svg"),
                iconBg: "#06f7a1",
                type: "dronos-success-toast",
              },
            },
            {
              toastClassName: "dronos-success-toast",
            },
          )
          setTimeout(() => {
            this.$router.push({ name: "login" })
          }, 2000)
        }).catch(error => {
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: error.response.data.message,
                // eslint-disable-next-line global-require
                img: require("@/assets/toastification/x.svg"),
                iconBg: "#ff4646",
              },
            },
            {
              toastClassName: "dronos-error-toast",
            },
          )
          if (error.response.status === 406) {
            this.$router.push({ name: 'no-workspace' })
          }
        })
      } catch (error) {
        this.TOAST(
          {
            component: DToast,
            ToastID: "custom-toast",
            props: {
              title: error.response.data.message,
              // eslint-disable-next-line global-require
              img: require("@/assets/toastification/x.svg"),
              iconBg: "#ff4646",
            },
          },
          {
            toastClassName: "dronos-error-toast",
          },
        )
      }
    },
  },
}
</script>

<style module lang="scss">
@import "@/scss/general/main.scss";
@import "@/scss/validation/valid-main.scss";
@import "@/scss/general/reset-password.scss";
@import "@/components/scss/asset.scss";
</style>
<style scoped>

button {
  display: flex;
  background: transparent;
  border: none;
  color: #EEF4F7;
  align-items: center;
  justify-content: center;
}

</style>
