<template>
  <transition-group
    name="fade"
    class="d-flex flex-column w-100"
    tag="span"
  >
    <div
      v-if="!noLabel"
      key="label"
      :class="$style['label-div']"
      class="d-flex"
    >
      <!-- <span WILL BE DELETED
        :style="[Object.keys(labelStyle).length === 0 ? '' : labelStyle]"
        :class="$style['label-style']"
      >{{ label }} </span><span :class="$style['sub-label']"> {{ subLabel }}</span>  WILL BE DELETED -->
      <span
        :class="$style['label-style']"
      >{{ label }} </span><span :class="$style['sub-label']"> {{ subLabel }}</span>
    </div>
    <span
      v-if="readOnly"
      key="value1"
    > {{ modelValue }}
    </span>
    <input
      v-else-if="disabled"
      key="value2"
      type="text"
      :class="[$style['dronos-formcontrol'], { [$style['disabled-cursor']]: disabled } ]"
      :placeholder="placeholder"
      :value="modelValue"
      :accept="accept"
      disabled
      :maxlength="maxlength"
      @input="handleInput"
    >
    <input
      v-else
      :id="label"
      key="value"
      :type="type"
      :maxlength="maxlength"
      :class="$style['dronos-formcontrol']"
      :placeholder="placeholder"
      :value="modelValue"
      :accept="accept"
      @input="handleInput"
      @keyup="$emit('keyup',modelValue)"
    >
    <!-- :min="mindates" -->
    <!-- </div> -->
  </transition-group>
</template>
<style module lang="scss">
@import '@/components/other/dronos-input/dronos-input.scss';
</style>
<script>
// import moment from 'moment'
export default {
  components: {
  },
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    // eslint-disable-next-line
    modelValue: { default: "" },
    // eslint-disable-next-line
    data: { default: "" },
    // eslint-disable-next-line
    placeholder: { default: null },
    noLabel: { default: false, type: Boolean },
    height: {
      type: String,
      default: '4rem',
    },
    label: {
      type: String,
      default: 'Label',
    },
    subLabel: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: 'Label',
    },
    type: {
      type: String,
      default: 'text',
    },
    accept: {
      type: String,
      default: '',
    },
    readOnly: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    labelStyle: {
      type: Object,
      default: () => ({
      }),
    },
    maxlength: {
      type: Number,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    borderInput: {
      type: String,
      default: 'none',
    },
  },
  data(){
    return {
      mindates: new Date(),
      isDate: false,
    }
  },
  mounted() {
    this.getPropDetails('inputHeight', this.height)
    this.getPropDetails('inputBorder', this.borderInput)
  },
  methods: {
    getPropDetails(id, value){
      const root = document.documentElement
      root.style.setProperty(`--${id}`, value)
    },
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
    handleChange(event) {
      if (this.label === 'start date') {
        this.mindates = this.modelValue
        document.getElementById('end date').min = this.mindates
      }
      this.$emit('change', event)
    },
    checkValue(){
      this.$emit('input', this.data)
    },
  },
}
</script>
