import { axiosIns } from "@app/utility";
export async function getUserInfo(authToken) {
  const config = {
    method: "GET",
    url: `/account/users/profile`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  return axiosIns(config);
}