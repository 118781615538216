// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".change-password_main-div_LnOxu{position:relative;background-color:#000}.change-password_sub-main-div_2v1_I{background-color:rgba(5,5,5,.75);position:absolute;z-index:1;width:100vw;height:100vh}.change-password_sub-main-div_2v1_I>.change-password_sub_hY7th{height:100vh}.change-password_input-name-width_jfGAC{width:48%}.change-password_contact-holder_3SMAT{background-color:#38414c;max-width:122px;width:100%;border-radius:22px;height:40px;color:#fff;position:fixed;z-index:1;top:92%;right:40px}.change-password_contact-holder_3SMAT .change-password_contact-btn-wrapper_1US0e{display:flex;align-items:center;padding:3px 4px 4px 4px}.change-password_contact-holder_3SMAT .change-password_contact-btn-wrapper_1US0e .change-password_help-icon_60flk{margin-right:6px}.change-password_contact-holder_3SMAT .change-password_contact-btn-wrapper_1US0e .change-password_help-text_1pMIO{font-size:14px;color:#fff;line-height:1.2}.change-password_error_3oA0T{color:red;margin-top:5px}.change-password_is-invalid_3iDw3{border-color:red}.change-password_change-label_3N84C{margin:10px 0;width:156px;height:24px;font-family:Barlow;font-style:normal;font-weight:400;font-size:17px;line-height:24px;text-align:center;color:#fff;flex:none;order:1;flex-grow:0}.change-password_change-div_24UTh{margin-top:74px;width:404px;height:550px;display:flex;justify-content:center;border:.2px solid #879aa6!important;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);border-radius:10px;flex-direction:column;align-items:center;border:none}.change-password_change-div_24UTh a{text-decoration:none}.change-password_label_pmd86{font-weight:400;font-size:14px;line-height:17px;letter-spacing:.4px;color:#fff;margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"main-div": "change-password_main-div_LnOxu",
	"sub-main-div": "change-password_sub-main-div_2v1_I",
	"sub": "change-password_sub_hY7th",
	"input-name-width": "change-password_input-name-width_jfGAC",
	"contact-holder": "change-password_contact-holder_3SMAT",
	"contact-btn-wrapper": "change-password_contact-btn-wrapper_1US0e",
	"help-icon": "change-password_help-icon_60flk",
	"help-text": "change-password_help-text_1pMIO",
	"error": "change-password_error_3oA0T",
	"is-invalid": "change-password_is-invalid_3iDw3",
	"change-label": "change-password_change-label_3N84C",
	"change-div": "change-password_change-div_24UTh",
	"label": "change-password_label_pmd86"
};
module.exports = exports;
