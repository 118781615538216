import { axiosIns } from "@app/utility";

export async function contactFormDataSubmit(data) {
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: "/account/contact-us",
    data,
  };
  return axiosIns(config);
}
