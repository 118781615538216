/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
import { axiosIns } from "@app/utility";

export class authService {
  async login(data) {
    const config = {
      method: "post",
      url: "/account/auth/login",
      data,
    }
    return await axiosIns(config)
  }
  register(data) {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/account/users/signup",
      data,
    }
    return axiosIns(config)
  }
  forgotPassword(data) {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/account/auth/forgot-password`,
      data,
    }
    return axiosIns(config)
  }
  resetPassword(data) {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `/account/auth/reset-password`,
      data,
    }
    return axiosIns(config)
  }
  changePassword(data) {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `/account/auth/change-password`,
      data,
    }
    return axiosIns(config)
  }
}
