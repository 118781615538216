<template>
  <div :class="[$style['contact-us-wrapper'], isPanelOpen ? $style['active'] : '']">
    <div :class="$style['contact-us-header']">
      <h3 :class="$style['title']">Contact Us</h3>
      <button class="btn" type="button" :class="$style['cross-btn']" @click="closeContactPanel">
        <img :src="imageConstants.crossIcon" alt="cross image" :class="$style['cross-icon']" />
      </button>
    </div>
    <form @submit.prevent="submitContactForm">
      <div :class="$style['name-wrapper']">
        <div class="form-group" :class="$style['form-field-group']">
          <label for="fname" :class="$style['form-label']">First Name</label>
          <input type="text" class="form-control" :class="[$style['form-field'], $style['name-input-field']]"
            placeholder="enter first name" name="fname" id="fname" v-model="formData.fname" @input="clearNameError" />
          <span v-if="!isNameValid" :class="$style['error-message']">First name is required.</span>
        </div>
        <div class="form-group" :class="$style['form-field-group']">
          <label for="lname" :class="$style['form-label']">Last Name</label>
          <input type="text" class="form-control" :class="[$style['form-field'], $style['name-input-field']]"
            placeholder="enter last name" name="lname" id="lname" v-model="formData.lname" />
          <div v-if="!isNameValid" :class="$style['mr-18']"></div>
        </div>
      </div>
      <div class="form-group" :class="$style['form-field-group']">
        <label for="email" :class="$style['form-label']">Email address</label>
        <input type="text" class="form-control" :class="$style['form-field']" placeholder="enter email" name="email"
          id="email" v-model="formData.email" @input="clearEmailError" />
        <span v-if="!isEmailValid" :class="$style['error-message']">Valid email is required.</span>
      </div>
      <div class="form-group" :class="$style['form-field-group']">
        <label for="description" :class="$style['form-label']">What can we help you with?</label>
        <textarea class="form-control" :class="[$style['form-field'], [$style['form-textarea']]]"
          placeholder="enter your message" id="desc" v-model="formData.description" @input="clearDescError"></textarea>
        <span v-if="!isDescriptionValid" :class="$style['error-message']">Message is required.</span>
      </div>
      <div ref="turnstile" class="my-4"></div>
      <button type="submit" class="btn" :class="$style['submit-btn']" :disabled="!captchaToken">submit</button>
    </form>
  </div>
  <contact-us-toast :isTicketToastOpen="formToast" :imageConstants="imageConstants"></contact-us-toast>
</template>
<script>

import { contactFormDataSubmit } from '../../src/api/contact-us.js'
import contactUsToast from './contact-us-toast.vue';
import { imageConstants } from '@/@core/utils/constants'

export default {
  components: { contactUsToast },
  name: "contact-us",
  props: {
    isPanelOpen: Boolean,
  },
  data() {
    return {
      formData: {
        fname: "",
        lname: "",
        email: "",
        description: "",
      },
      isNameValid: true,
      isEmailValid: true,
      isSubjectValid: true,
      isDescriptionValid: true,
      captchaToken: null,
      formToast: false,
      imageConstants
    };
  },
  methods: {
    closeContactPanel() {
      this.$emit('closeContactUsPanel', false)
      this.captchaToken = null
      window.turnstile.remove();
    },
    isFormValid() {
      this.isNameValid = !!this.formData.fname;
      this.isEmailValid = this.validateEmail(this.formData.email);
      this.isDescriptionValid = !!this.formData.description;
      return this.isNameValid && this.isEmailValid && this.isDescriptionValid
    },
    clearNameError() {
      this.isNameValid = true;
    },
    clearEmailError() {
      this.isEmailValid = true;
    },
    clearDescError() {
      this.isDescriptionValid = true;
    },
    async submitContactForm() {
      if (this.captchaToken) {
        if (this.isFormValid()) {
          const postData = {
            firstName: this.formData.fname,
            lastName: this.formData.lname,
            email: this.formData.email,
            description: this.formData.description,
            captchaToken: this.captchaToken
          };
          await contactFormDataSubmit(postData);
          this.closeContactPanel();
          this.formData.fname = "";
          this.formData.lname = "";
          this.formData.email = "";
          this.formData.description = "";
          setTimeout(() => {
            this.formToast = true;
            setTimeout(() => {
              this.formToast = false;
            }, 1000);
          }, 1000);
        }
        else {
          window.turnstile.reset()
        }
      }
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    onCaptchaVerify(value) {
      this.captchaToken = `${value}`
    },
    getTurnstileOption() {
      return {
        sitekey: `${process.env.VUE_APP_SITE_KEY}`,
        theme: 'dark',
        callback: this.onCaptchaVerify,
      }
    },
    renderCaptcha() {
      window.turnstile.render(this.$refs.turnstile, this.getTurnstileOption());
    },
    ensureTurnstile() {
      const script = document.createElement('script');
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit";
      script.async = false;
      script.defer = false;

      script.onload = () => {
        this.renderCaptcha();
      };

      script.onerror = (error) => {
        console.error('Failed to load Cloudflare Turnstile API script', error);
      };
      document.head.appendChild(script);
    },
  },
  watch: {
    isPanelOpen() {
      if (this.isPanelOpen) {
        this.ensureTurnstile()
      }
    }
  }
};
</script>
<style module lang="scss">
@import "@/scss/general/contact-us.scss";
</style>


