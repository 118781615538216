// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".no-workspace-module_sub-main-div_3AqqF{background-color:rgba(5,5,5,.75);position:absolute;z-index:10;width:100vw;height:100vh}.no-workspace-module_login-card_3b1mo,.no-workspace-module_sub-main-div_3AqqF>.no-workspace-module_sub_2KfQU{height:100vh}.no-workspace-module_login-card_3b1mo{background:rgba(34,38,49,.6);-webkit-backdrop-filter:blur(50px);backdrop-filter:blur(50px)}@media screen and (min-width:0px){.no-workspace-module_hidden-logo_1FZnk{display:none;scale:.6}}@media screen and (min-width:420px){.no-workspace-module_hidden-logo_1FZnk{display:block;scale:.6}}.no-workspace-module_welcome-text_1G83s{font-weight:200;font-size:28px;line-height:24px;color:#fff}", ""]);
// Exports
exports.locals = {
	"sub-main-div": "no-workspace-module_sub-main-div_3AqqF",
	"login-card": "no-workspace-module_login-card_3b1mo",
	"sub": "no-workspace-module_sub_2KfQU",
	"hidden-logo": "no-workspace-module_hidden-logo_1FZnk",
	"welcome-text": "no-workspace-module_welcome-text_1G83s"
};
module.exports = exports;
