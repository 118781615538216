// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".reset-password_main-div_EQ4QC{position:relative;background-color:#000}.reset-password_sub-main-div_KOfMx{background-color:rgba(5,5,5,.75);position:absolute;z-index:1;width:100vw;height:100vh}.reset-password_sub-main-div_KOfMx>.reset-password_sub_2LA5r{height:100vh}.reset-password_input-name-width_2U2xt{width:48%}.reset-password_contact-holder_9DCpT{background-color:#38414c;max-width:122px;width:100%;border-radius:22px;height:40px;color:#fff;position:fixed;z-index:1;top:92%;right:40px}.reset-password_contact-holder_9DCpT .reset-password_contact-btn-wrapper_JHreK{display:flex;align-items:center;padding:3px 4px 4px 4px}.reset-password_contact-holder_9DCpT .reset-password_contact-btn-wrapper_JHreK .reset-password_help-icon_isS7O{margin-right:6px}.reset-password_contact-holder_9DCpT .reset-password_contact-btn-wrapper_JHreK .reset-password_help-text_3ahdL{font-size:14px;color:#fff;line-height:1.2}.reset-password_error_3gZ57{color:red;margin-top:5px}.reset-password_is-invalid_3wyYw{border-color:red}.reset-password_forget-div_1FWPu{margin-top:74px;width:404px;height:434px;background:rgba(34,38,49,.6);display:flex;justify-content:center;border:.2px solid #879aa6!important;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);border-radius:10px;flex-direction:column;align-items:center;border:none}.reset-password_forget-div_1FWPu a{text-decoration:none}.reset-password_forget-label_30AwM{margin:10px 0;width:156px;height:24px;font-size:17px;line-height:24px;text-align:center;flex:none;order:1;flex-grow:0}.reset-password_forget-label_30AwM,.reset-password_label-style_2sSYF{font-family:Barlow;font-style:normal;font-weight:400;color:#fff}.reset-password_label-style_2sSYF{font-size:14px;line-height:17px;display:flex;align-items:center;letter-spacing:.4px}", ""]);
// Exports
exports.locals = {
	"main-div": "reset-password_main-div_EQ4QC",
	"sub-main-div": "reset-password_sub-main-div_KOfMx",
	"sub": "reset-password_sub_2LA5r",
	"input-name-width": "reset-password_input-name-width_2U2xt",
	"contact-holder": "reset-password_contact-holder_9DCpT",
	"contact-btn-wrapper": "reset-password_contact-btn-wrapper_JHreK",
	"help-icon": "reset-password_help-icon_isS7O",
	"help-text": "reset-password_help-text_3ahdL",
	"error": "reset-password_error_3gZ57",
	"is-invalid": "reset-password_is-invalid_3wyYw",
	"forget-div": "reset-password_forget-div_1FWPu",
	"forget-label": "reset-password_forget-label_30AwM",
	"label-style": "reset-password_label-style_2sSYF"
};
module.exports = exports;
