<template>
  <div :class="$style['main-div']">
    <div :class="$style['sub-main-div']">
      <div class="d-flex justify-content-center">
        <div :class="$style['change-div']">
          <div>
            <img
              width="105"
              src="@/assets/images/logo.svg"
              alt="aerodyne-logo">
          </div>
          <div>
            <label
              for=""
              :class="$style['change-label']"
            >Change Password</label>
          </div>
          <div>
            <!-- <form action=""> -->
            <div class="mb-3">
              <dronos-input-password
                v-model="currentPassword"
                label="Current Password"
                placeholder="Enter Current Password"
                :class="{ [$style['is-invalid']]: isCurrentPasswordEmpty }" />
              <div
                v-if="isCurrentPasswordEmpty"
                class="mb-4"
                :class="$style.error">
                This field is required
              </div>
            </div>
            <div class="mb-3">
              <dronos-input-password
                v-model="newPassword"
                label="New Password"
                placeholder="Enter New Password"
                :class="{ [$style['is-invalid']]: !isPasswordValid }" :guidance="true" />
              <div
                v-if="!isPasswordValid"
                class="mb-4"
                :class="$style.error">
                Password should not contain less than 8 characters
              </div>
            </div>
            <div>
              <dronos-input-password
                id="login-password"
                v-model="retypePassword"
                label="Confirm New Password"
                placeholder="Enter New Password"
                :class="{ [$style['is-invalid']]: !isPasswordConfirmValid }" />
              <div
                v-if="!isPasswordConfirmValid"
                class="mb-4"
                :class="$style.error">
                {{ errorPasswordConfirm }}
              </div>
            </div>
            <div>
              <Btn
                widithbtn="340"
                heightbtn="0"
                title="CHANGE PASSWORD"
                @click="validatePassword" />
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
    <background-image />
  </div>
</template>
<script>
import button from "@/components/other/dronos-button.component.vue"
import BackgroundImage from "@/views/background-image.vue"
import DronosInputPassword from "@/components/other/dronos-input/dronos-input-password.component.vue"
import { authService } from "@/api/auth"
import { state } from "@app/utility"
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"
import { useToast } from "vue-toastification"
import { ERROR_MSG } from '@core/utils/constants'

export default {
  components: {
    Btn: button,
    BackgroundImage,
    DronosInputPassword,
  },
  data() {
    const TOAST = useToast()
    return {
      // eslint-disable-next-line new-cap
      authService: new authService(),
      currentPassword: "",
      newPassword: "",
      retypePassword: "",
      errorPasswordConfirm: "This field is required",
      isCurrentPasswordEmpty: false,
      isPasswordValid: true,
      isPasswordConfirmValid: true,
      email: "",
      isEmailValid: true,
      TOAST,
    }
  },
  methods: {
    validatePassword() {
      this.isCurrentPasswordEmpty = this.currentPassword.length === 0
      this.isPasswordValid = this.newPassword.length > 7
      this.isPasswordConfirmValid = this.retypePassword === this.newPassword
      if (this.retypePassword.length > 0 && this.retypePassword !== this.newPassword) {
        this.errorPasswordConfirm = "The password does not match"
      }

      if (this.isPasswordValid && this.isPasswordConfirmValid) {
        this.resetPassword()
      }
    },
    resetPassword() {
      const { email } = state.getUserInfo()
      if (email !== null) {
        const data = {
          email,
          oldPassword: this.currentPassword,
          newPassword: this.retypePassword,
        }
        try {
          this.authService.changePassword(data).then(response => {
            this.TOAST.clear()
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: response.data.message,
                  img: require("@/assets/toastification/user.svg"),
                  iconBg: "#06f7a1",
                  type: "dronos-success-toast",
                },
              },
              {
                toastClassName: "dronos-success-toast",
              },
            )
            setTimeout(() => {
              state.destroyDronosToken();
              window.location.href = "/login"
            }, 500)
          }).catch(error => {
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: error.response.data.message.length === 1 ? error.response.data.message[0] : error.response.data.message,
                  // eslint-disable-next-line global-require
                  img: require("@/assets/toastification/x.svg"),
                  iconBg: "#ff4646",
                },
              },
              {
                toastClassName: "dronos-error-toast",
              },
            )
          })
        } catch {
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: ERROR_MSG.GENERAL,
                // eslint-disable-next-line global-require
                img: require("@/assets/toastification/x.svg"),
                iconBg: "#ff4646",
              },
            },
            {
              toastClassName: "dronos-error-toast",
            },
          )
        }
      }
    },
  },
}
</script>

<style module lang="scss">
@import "@/scss/general/main.scss";
@import "@/scss/validation/valid-main.scss";
@import "@/scss/general/change-password.scss";
</style>
<style scoped>
button {
        display: flex;
        background: transparent;
        border: none;
        color: #EEF4F7;
        align-items: center;
        justify-content: center;
    }
</style>
