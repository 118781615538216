<template>
  <div>
    <input
      v-for="(digit, index) in otpDigits"
      :key="index"
      :ref="getRef(index)"
      v-model="otp[index]"
      :class="[$style['pincode']]"
      maxlength="1"
      class="otp-input"
      @paste="onPaste(index, $event)"
      @keydown="onKeyDown(index, $event)"
    >
  </div>
</template>
<script>
import { ERROR_MSG } from '@core/utils/constants'

export default {
  name: 'OtpInput',
  props: {
    setOtpValue: Function,
  },
  data() {
    return {
      pinNumber: [],
      pinErrorMessage: '',
      otpDigits: [0, 1, 2, 3, 4, 5],
      otp: Array(6).fill(''),
    }
  },
  watch: {
    errorMessage: {
      handler(val) {
        if (val) {
          this.pinErrorMessage = ERROR_MSG.WRONG_PIN
          const inputs = document.querySelectorAll(".otp-input")
          const conInputs = Array.from(inputs)
          conInputs.forEach(x => {
            // eslint-disable-next-line no-param-reassign
            x.value = ''
            inputs[0].focus()
          })
        } else {
          this.pinErrorMessage = ''
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    onPaste(index, event) {
      event.preventDefault()
      const pastedData = event.clipboardData.getData('text/plain')
      const pastedDigits = pastedData.match(/\d/g)
      if (pastedDigits) {
        for (let i = 0; i < pastedDigits.length && index + i < this.otp.length; i++) {
          this.$set(this.otp, index + i, pastedDigits[i])
        }
        const nextIndex = index + pastedDigits.length
        if (nextIndex < this.otp.length) {
          this.$refs[this.getRef(nextIndex)][0].focus()
        }
      }
      this.setOtpValue(this.otp)
    },
    onKeyDown(index, event) {
      const digit = event.key
      if (/^\d$/.test(digit)) {
        this.$set(this.otp, index, digit)
        if (index < this.otp.length - 1) {
          this.$refs[this.getRef(index + 1)][0].focus()
        }
        event.preventDefault()
      } else if (event.key === 'ArrowLeft' && index > 0) {
        this.$refs[this.getRef(index - 1)][0].focus()
        event.preventDefault()
      } else if (event.key === 'ArrowRight' && index < this.otp.length - 1) {
        this.$refs[this.getRef(index + 1)][0].focus()
        event.preventDefault()
      } else if (event.key === 'Backspace') {
        this.$set(this.otp, index, '')
        if (index > 0) {
          this.$refs[this.getRef(index - 1)][0].focus()
        }
        event.preventDefault()
      } else if (event.key === 'v' && (event.metaKey || event.ctrlKey)) {
        return
      } else {
        event.preventDefault()
      }
      this.setOtpValue(this.otp)
    },
    getRef(index) {
      return `input-${index}`
    },
  },
}
</script>
<style scoped module lang="scss">
@import '@/scss/component/component-main.scss';
</style>
