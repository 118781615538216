<template>
  <div class="m-2">
    <div
      class="d-flex align-items-center"
    >
      <div
        class="d-flex align-items-center justify-content-center"
        :class="$style['background-icon']"
      >
        <img
          v-if="img"
          :src="img"
          alt="no picture"
          width="20"
          height="20"
        >
      </div>
      <div class="d-flex flex-grow-1 ml-2">
        <div class="d-flex flex-column">
          <span
            v-if="title"
            class="mb-0 font-weight-bolder"
            :class="$style['toastification-title']"
            v-text="title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      default: 'light',
    },
    titleColor: {
      type: String,
      default: '#FFFFFF',
    },
    iconColor: {
      type: String,
      default: 'black',
    },
    iconBg: {
      type: String,
      default: '#06F7A1',
    },
    typeBorder: {
      type: String,
      default: '',
    },
    radiusBg: {
      type: String,
      default: '50%',
    },
    icon: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: true,
    },
    hyperLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    this.getPropDetails('backgroundIcon', this.iconBg)
    this.getPropDetails('borderRadiusIcon', this.radiusBg)
    this.getPropDetails('titleColor', this.titleColor)
  },
  methods: {
    getPropDetails(id, value){
      const root = document.documentElement
      root.style.setProperty(`--${id}`, value)
    },
  },
}
</script>

<style lang="scss" module>
@import '@/scss/libs/toastification.scss';
</style>
