<template>
  <div :class="$style['login-container']">
    <div class="row">
      <div class="col-7" style="padding-right: 0;" :class="$style['left-side']">
        <div :class="$style['img-left-side']"></div>
      </div>

       <div class="col-5" style="padding: 0;" :class="$style['bg-blur']">
        <div :class="$style['login-card']" class="m-0 d-flex align-items-center justify-content-center">
        <div :class="$style['w-30']">
          <div class="d-flex justify-content-center" :class="$style['welcome-text']">
            Welcome to DRONOS
          </div>
          <div class="mt-5">
            <div class="w-100" :class="[$style['login-label']]">
              Email Address</div>
            <d-input-light v-model="email" class="mt-1" :no-label="true" placeholder="Enter Email"
                           :class="!isEmailValid ? [$style['is-invalid']] : 'mb-4'" />
            <div v-if="!isEmailValid" class="mb-4" :class="$style.error">
              Please enter a valid email
            </div>
            <div class="">
              <div>
                <div class="mt-1">
                  <d-password id="login-password" v-model="password" label="Password" placeholder="Enter Password"
                              :class="[!isPasswordValid ? [$style['is-invalid']] : 'mb-4']" @enter="validateFields" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-4">
              <label :class="[$style['checkbox-wrapper']]">
                <input :class="[$style['remember-me-checkbox']]" :value="isRememberMeSelected" type="checkbox" @change="handleRememberMe" />
                <span :class="[$style['remember-me-checkmark']]"></span>
                <span :class="[$style['remember-me-label']]">Remember me</span>
              </label>
              <div :class="[$style.pointer, $style['forgot-password-container']]">
                <router-link :to="{ name: 'forgot-password' }">
                  Forgot Password?
                </router-link>
              </div>
            </div>
            <div class="">
              <button ref="loginBtn" :class="[$style['on-hover'], $style['login-btn']]" class="btn"
                      :disabled="loginButtonCheck ||  (!email.length || !password.length || !isBtnClicked)" @click="validateFields">
                <span :class="$style['login-text']">LOGIN</span>
              </button>
            </div>
            <!-- <div class="d-flex justify-content-center mt-4">
              <div :class="$style['or-login-with-text']">
                or login with
              </div>
            </div> -->
            <!-- <div class="d-flex flex-row justify-content-center" :class="$style['logo-div']">
              <img src="@/assets/images/icons/microsoft.svg" alt="microsoft-logo">
              <img src="@/assets/images/icons/google.svg" class="ml-1" alt="google-logo">
            </div> -->
            <!-- <div class="d-flex flex-row justify-content-center" :class="$style['account-div']">
              <span :class="$style['very-light-grey']">
                Don’t have account?
              </span>
              <div :class="$style['signup-text']">
                <router-link to="/register">
                  Sign Up
                </router-link>
              </div>
            </div> -->
          </div>
        </div>
      </div>

       
      </div>

    </div>
  </div>
</template>

<script>
import DInputLight from '@/components/other/dronos-input/dronos-input-light.component.vue'
import { authService } from '@/api/auth'
import DPassword from "@/components/other/dronos-input/dronos-input-password.component.vue"
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"
import { useToast } from "vue-toastification"
import { MAXCOUNTER, OTP_EXPIRY_TIME } from '@/views/login/login-constant'
import { continueToLogin } from '@core/utils/login/login-util'
import { ERROR_MSG } from '@core/utils/constants'

export default {
  components: {
    DInputLight,
    DPassword,
  },
  data() {
    const TOAST = useToast()
    return {
      authService: new authService(),
      email: '',
      password: '',
      prevemail: '',
      prevpass: '',
      isStayed: false,
      loginButtonCheck: false,
      passwordType: 'password',
      isEmailValid: true,
      isPasswordValid: true,
      TOAST,
      isBtnClicked: false,
      nextTickCounter: 0,
      isRememberMeSelected: false,
      clearToastTimeout: 1800,
      errorToastTimeout: 2150,
    }
  },
  watch: {
    password: {
      handler(val) {
        if (this.isEmailValid === true && this.isPasswordValid === true) {
          this.isBtnClicked = true
        } else {
          this.isBtnClicked = false
        }
        if (val.length > 0) {
          this.isShowIcon = true
        } else {
          this.isShowIcon = false
        }
      },
    },
    email: {
      handler(val){
        this.isEmailValid = val === '' ? true : /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)
        if (this.isEmailValid === true && this.isPasswordValid === true) {
          this.isBtnClicked = true
        } else {
          this.isBtnClicked = false
        }
      },
    },
  },
  mounted() {
    this.nextTick()
  },
  methods: {
    checkAutoFill() {
      if (this.nextTickCounter > MAXCOUNTER) {
        return
      }
      const autofill = document.querySelector('input:-webkit-autofill')
      if (autofill === null) {
        this.nextTick()
      }
    },
    nextTick() {
      setTimeout(() => {
        this.nextTickCounter += 1
        this.checkAutoFill()
      })
    },
    validateFields() {
      if (this.isEmailValid && this.isPasswordValid) {
        this.loginPass()
      }
    },
    showPass() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    loginPass() {
      this.loginButtonCheck=true;
      const data = JSON.stringify({
        email: this.email,
        password: this.password,
      })
      try {
        this.authService.login(data).then(async response => {
          const token = response.data.token;
          const message = response.data.message;
          const isUserVerified = response.data.verified;
          const changePasswordRequired = response.data.changePasswordRequired
          this.TOAST.clear()
          if (token) {
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: message,
                  img: require("@/assets/toastification/user.svg"),
                  iconBg: "#06f7a1",
                  type: "dronos-success-toast",
                },
              },
              { toastClassName: "dronos-success-toast" },
            );
            this.clearToast()
            await continueToLogin({ token, message, isUserVerified, email: this.email, router: this.$router });
          } else if (changePasswordRequired) {
            localStorage.setItem('email-verify', this.email)
            this.$router.push({ name: 'reset-password' })
          } else {
            this.loginButtonCheck=false
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: message,
                  img: require("@/assets/toastification/user.svg"),
                  iconBg: "#06f7a1",
                  type: "dronos-success-toast", 
                }
              },
              { toastClassName: "dronos-success-toast" },
            );
            this.clearToast()
            const { otpExpiryTimeInSeconds } = response.data
            localStorage.setItem(OTP_EXPIRY_TIME, otpExpiryTimeInSeconds)
            this.$router.push({
              name: 'verify-email-otp',
              params: {
                email: this.email,
                type: 'otp-login-',
                rememberMe: this.isRememberMeSelected,
              }
            });
          }
        }).catch(error => {
          this.loginButtonCheck=false
          this.isBtnClicked = false
          setTimeout(() => {
            this.TOAST(
              {
                component: DToast,
                ToastID: "custom-toast",
                props: {
                  title: error.response.data.message,
                  // eslint-disable-next-line global-require
                  img: require("@/assets/toastification/x.svg"),
                  iconBg: "#ff4646",
                },
              },
              {
                toastClassName: "dronos-error-toast",
              },
            )
            this.clearToast()
          }, this.errorToastTimeout)
          if (error.response.status === 406) {
            this.$router.push({ name: 'no-workspace' })
          }
        })
      } catch {
        this.TOAST(
          {
            component: DToast,
            ToastID: "custom-toast",
            props: {
              title: ERROR_MSG.GENERAL,
              // eslint-disable-next-line global-require
              img: require("@/assets/toastification/x.svg"),
              iconBg: "#ff4646",
            },
          },
          {
            toastClassName: "dronos-error-toast",
          },
        )
      }
    },

    handleRememberMe(event) {
      this.isRememberMeSelected = event.target.checked;
    },
    clearToast() {
      setTimeout(() => {
        this.TOAST.clear()
      }, this.clearToastTimeout)
    },
  },
}
</script>
<style module lang="scss">
@import '@/scss/general/login.scss';
@import '@/scss/validation/valid-main.scss';
@import '@/scss/theme/theme.scss';
</style>
