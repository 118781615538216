<template>
  <div>
    <div :class="$style['margin-div']">
      <button
        :class="$style['button-custom']"
        type="button"
        @click="$emit('click')"
      >
        {{ title }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props:
    {
      title:
        {
          type: String,
          required: true,
        },

      widithbtn:
        {
          type: String,
          required: true,
        },
      heightbtn:
        {
          type: String,
          required: true,
        },
      marginTop: {
        type: String,
        default: '15',
      },
    },
  mounted() {
    this.getPropDetails('widthBtn', this.widithbtn)
    this.getPropDetails('heightBtn', this.heightbtn)
    this.getPropDetails('marginDiv', this.marginTop)
  },
  methods: {
    getPropDetails(id, value){
      const root = document.documentElement
      root.style.setProperty(`--${id}`, `${value}px`)
    },
  },
}
</script>

<style scoped module lang="scss">
@import '@/components/scss/button.scss';
</style>
