<template>
  <div :class="$style['main-div']">
    <div :class="$style['sub-main-div']">
      <div :class="$style.sub" class="d-flex flex-row">
        <!-- <div class=" leftDiv"> -->
        <label :class="$style.text" class="col-5 d-flex flex-column justify-content-center">
          <span>
            Elevate your business
          </span>
          <span>
            operations with precision
          </span>
          <span>
            drone monitoring
          </span>
        </label>
        <!-- </div> -->
        <div :class="$style['right-div']" class="col-7">
          <div class="d-flex justify-content-center flex-column">
            <div class="d-flex flex-wrap align-items-center justify-content-center">
              <img src="@/assets/images/logo.svg" alt="aerodyne-logo">
            </div>
            <!-- <form
                action=""
                method="post"
              > -->
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div :class="$style['input-name-width']">
                  <label :class="$style['label-style']">First Name</label>
                  <input v-model="firstName" type="text" placeholder="Enter First Name" name="fName"
                         :class="[$style['dronos-formcontrol'], { [$style['is-invalid']]: !isFnamevalid }]">
                  <div v-if="!isFnamevalid" :class="$style.error">
                    First name is required
                  </div>
                </div>
                <div :class="$style['input-name-width']">
                  <label :class="$style['label-style']">Last Name</label>
                  <input v-model="lastName" type="text" placeholder="Enter Last Name" name="fName"
                         :class="[$style['dronos-formcontrol'], { [$style['is-invalid']]: !isFnamevalid }]">
                  <div v-if="!isLnamevalid" :class="$style.error">
                    Last name is required
                  </div>
                </div>
              </div>
              <div>
                <label :class="$style['label-style']">Email</label>
                <input v-model="email" type="email" class="" :no-label="true" placeholder="Enter email" name="Email"
                       :class="[$style['dronos-formcontrol'], { [$style['is-invalid']]: !isEmailValid }]">
                <div v-if="!isEmailValid" class="mb-4" :class="$style.error">
                  Please enter a valid email
                </div>
              </div>
              <div>
                <d-password id="register-password" v-model="password" label="Password" placeholder="Enter Password"
                            class="mt-3" :class="{ [$style['is-invalid']]: !isPasswordValid }" :guidance="true" @keyup="checkLength" />
                <div v-if="!isPasswordValid" :class="$style.error" class="mb-4">
                  Password should be more that 8 characters
                </div>
              </div>
              <div :class="$style['padding-button-account']">
                <button :disabled="registeringUser" :class="[$style.onHover, $style['create-btn']]" class="btn" @click="validateSignup">
                  <span :class="$style['create-text']">CREATE ACCOUNT</span>
                </button>
              </div>
            </div>
            <div :class="$style['right-footer-div']" class="d-flex justify-content-start">
              <span :class="$style['have-account']">Already have an account?
                <router-link :to="{ name: 'login' }">
                  <button :class="[$style['login-btn']]" @click="$emit('login')">Login</button>
                </router-link>
              </span>
            </div>
            <!-- <div class="d-flex justify-content-center mt-3">
              <hr :class="$style['hr-style']">
              <span :class="$style['sign-up-with']">or sign up with</span>
              <hr :class="$style['hr-style']">
            </div> -->
            <div class="d-flex flex-wrap justify-content-center" :class="$style['register-icon-div']">
              <!-- <img src="@/assets/images/icons/microsoft.svg" alt="microsoft-logo">
              <img src="@/assets/images/icons/google.svg" alt="google-logo"> -->
            </div>
            <!-- </div> -->
            <div :class="$style.tnc">
              By registering, you accept our
              <span :class="$style['default-color']">Terms & Conditions </span>and <span
                :class="$style['default-color']">Privacy & Policy. </span>
              <br>
              Your data will be securely encrypted.
            </div>
          </div>
        </div>
      </div>
      <!-- <template v-else>
        <verify-link @back-to-login="$router.push({ name: 'login' })" />
      </template> -->
    </div>
    <background-image />
  </div>
</template>
<script>
import BackgroundImage from '@/views/background-image.vue'
import DPassword from "@/components/other/dronos-input/dronos-input-password.component.vue"
import VerifyLink from '@/components/other/verify-signup.component.vue'
import { authService } from '@/api/auth'
import DToast from "@/components/other/toastification/dronos-toastification.component.vue"
import { useToast } from "vue-toastification"
import router from '../router'
import { ERROR_MSG } from '@core/utils/constants'

export default {
  name: "SignUp",
  components: {
    BackgroundImage,
    DPassword,
    VerifyLink,
  },
  data() {
    const TOAST = useToast()
    return {
      // data here
      // eslint-disable-next-line new-cap
      authService: new authService(),
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      username: '',
      isStayed: false,
      passwordType: 'password',
      isShowIcon: false,
      // isRegister: true,
      isFnamevalid: true,
      isLnamevalid: true,
      isEmailValid: true,
      isPasswordValid: true,
      registeringUser: false,
      TOAST,
    }
  },
  methods: {
    validateSignup() {
      this.isFnamevalid = this.firstName !== ""
      this.isLnamevalid = this.lastName !== "" 
      this.isEmailValid = this.email !== "" ?? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)
      this.isPasswordValid = this.password.length > 7
      if (this.isEmailValid && this.isPasswordValid && this.isFnamevalid && this.isLnamevalid && !this.registeringUser) {
        this.registerUser()
      }
    },
    showPass() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    registerUser() {
      const data = JSON.stringify({
        firstName: this.firstName,
        lastName: this.lastName,
        profileName: `${this.firstName} ${this.lastName}`,
        username: `${this.firstName} ${this.lastName}`,
        password: this.password,
        email: this.email,
        roles: "pilotInformation",
        website: "",
        profilePicture: "",
        coverPhoto: "",
        socialLink: [
          "",
        ],
        pilotInformation: [
          "",
        ],
      })
      try {
        this.registeringUser = true;
        this.authService.register(data).then(() => {
          this.registeringUser = false
          this.TOAST.clear()
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: 'Success! OTP sent at given email',
                img: require("@/assets/toastification/user.svg"),
                iconBg: "#06f7a1",
                type: "dronos-success-toast",
              },
            },
            {
              toastClassName: "dronos-success-toast",
            },
          )
          setTimeout(() => {
            this.$router.push({ name: 'verify-email-otp', params: { email: this.email, type: 'email-verification' } })
          }, 2000)
        }).catch(error => {
          this.registeringUser = false;
          this.TOAST(
            {
              component: DToast,
              ToastID: "custom-toast",
              props: {
                title: error.response.data.message,
                // eslint-disable-next-line global-require
                img: require("@/assets/toastification/x.svg"),
                iconBg: "#ff4646",
              },
            },
            {
              toastClassName: "dronos-error-toast",
            },
          )
        })
      } catch {
        this.TOAST(
          {
            component: DToast,
            ToastID: "custom-toast",
            props: {
              title: ERROR_MSG.GENERAL,
              // eslint-disable-next-line global-require
              img: require("@/assets/toastification/x.svg"),
              iconBg: "#ff4646",
            },
          },
          {
            toastClassName: "dronos-error-toast",
          },
        )
        this.registeringUser = false
      }
    },
  },
  mounted() {
    if(!this.$route.query.email)  {
      setTimeout(() => {
        this.$router.push({ name: 'login' })
      }, 100)
    }
  }
}
</script>
<style module lang="scss">
@import '@/scss/validation/valid-main.scss';
@import '@/scss/general/register.scss';
@import '@/scss/general/main.scss';
@import '@/components/other/dronos-input/dronos-input.scss';
</style>
<style scoped></style>
